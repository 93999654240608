import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    .ant-select-selection__placeholder{
    color : blue;
    }

    @font-face {
        font-family: "Space Mono";
        src: url("/fonts/SpaceMono-Regular.ttf") format("truetype");
        font-style: normal;
    }


    @font-face {
        font-family: "League Spartan";
        src: url("/fonts/League_Spartan/LeagueSpartan-VariableFont_wght.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/Montserrat/Montserrat-VariableFont_wght.ttf") format("truetype");
        font-style: normal;
    }
        

    body,
    html,
    a {
        font-family: 'League Spartan', sans-serif;
    }

    .ant-select-selection__placeholder{
        color : blue;
        }

    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #071813;
        overflow-x: hidden;
    }

    a:hover {
        color: #18216d;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 1px solid #00FFC3;
        background: #071813;
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;
        color: #fff;

        :focus-within {
            box-shadow: #2e186a 0px 0px 0px 1px;
            border: 1px solid #fff;
        }
    }

    ul {
        list-style-type: none;
        font-family: 'Montserrat', sans-serif;
        padding-left: 0.4rem;
        color: #fff;
        font-size: 23px;        
        line-height: 1.51;
        font-weight: 400;
        @media only screen and (max-width: 890px) {
            font-size: 20px;
        }

        @media only screen and (max-width: 414px) {
            font-size: 18px;
        }
    }
      
    h1 {
        font-family: 'League Spartan', sans-serif;
        color: #fff;
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 44px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 29px;
        }
    }

    h2 {
        font-family: 'League Spartan', sans-serif;
        color: #fff;
        font-size: 50px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 39px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 22px;
        }
    }

    h3 {
        font-family: 'League Spartan', sans-serif;
        color: #fff;
        font-size: 40px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
            font-size: 32px;
        }
        
        @media only screen and (max-width: 414px) {
            font-size: 22px;
        }
    }

    p {
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        font-size: 23px;        
        line-height: 1.4;
        font-weight: 400;
        text-align: justify;

        @media only screen and (max-width: 890px) {
            font-size: 20px;
        }

        @media only screen and (max-width: 414px) {
            font-size: 18px;
        }
    }

    a {
        text-decoration: none;
        outline: none;
        color: #45a29e;

        :hover {
            color: #45a29e;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
`;
