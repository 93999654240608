import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";

import Router from "./router";
import i18n from "./translation";

const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  </BrowserRouter>
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement );
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


// ReactDOM.createRoot(document.getElementById("root")).render(<App />,)
